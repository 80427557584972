import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import Hero from "../components/Hero/Hero";
import Item from "../components/Item/Item";
import Heading from "../components/Heading/Heading";
import { WorkListQuery } from "../../graphql-types";
import { ParallaxProvider } from "react-scroll-parallax";
import styled from "styled-components";

const StyledContainer = styled.div`
    display: grid;
    row-gap: 2.75rem;
    @media screen and (min-width: 600px) {
        row-gap: 3.25rem;
    }
    @media screen and (min-width: 1024px) {
        row-gap: 4rem;
    }
`;

const StyledProjectGrid = styled.div`
    display: grid;
    gap: 1rem;

    @media screen and (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1024px) {
        padding-top: 2rem;
        grid-template-columns: repeat(3, 1fr);
        gap: 3.5rem;
    }
`;

export type IndexPageProps = PageProps & {
    data: WorkListQuery;
};

const IndexPage: React.FC<IndexPageProps> = ({
    data: {
        allMdx: { edges },
    },
}) => (
    <Layout>
        <Seo title="Home" />
        <StyledContainer>
            <Hero />
            <div>
                <Heading.Section title="Things I've built" />
                <Item.Plugin
                    name="Protofly"
                    description="Create, save and reuse prototype interactions"
                    url="https://www.figma.com/community/plugin/1021879782774011952/Protofly"
                />
                <Item.Plugin
                    name="Topo"
                    description="Review and report components from Figma to Slack"
                    url="https://topo.santiagomoreno.co/"
                />
                <Item.Plugin
                    name="Eezin"
                    description="Preview, compare, use and learn about standard easing functions"
                    url="https://www.figma.com/community/plugin/888982951322498361/eezin"
                />
                <Item.Plugin
                    name="Connect"
                    description="Mirror updateable screens to global files without duplicating your content"
                    url="https://www.figma.com/community/plugin/888982951322498361/eezin"
                />
                <Item.Plugin
                    name="Quickfire"
                    description="Quickly replace Smart Objects and export to JPG or PNG"
                    url="https://github.com/sntiagomoreno/quickfire"
                />
            </div>
            <div>
                <Heading.Section title="Case Studies" />
                {edges.map((item, i) => (
                    <Item.Work
                        name={item.node.frontmatter.title}
                        path={item.node.slug}
                        description={item.node.frontmatter.tagline}
                    />
                ))}
            </div>
            <div>
                <Heading.Section title="Other projects" />
                <StyledProjectGrid>
                    <Item.Other
                        name="Loan Strategy – Lulo Bank"
                        description="Product Design."
                        nda
                    />
                    <Item.Other
                        name="Core Experience – Lulo Bank"
                        description="Product Design for the bank app's core experience. Case coming soon."
                        cta={{
                            text: "View website",
                            url: "https://www.behance.net/gallery/134358651/Lulo-Bank-Experience",
                        }}
                    />
                    <Item.Other
                        name="Side Hustles"
                        description="Design and dev for an internal web app to push local talent at Huge."
                    />
                    <Item.Other
                        name="Google 237"
                        description="Concept and final animations for the Google 237 Building project."
                    />
                    <Item.Other
                        name="Nike Air Max by Bastarda Type"
                        description="Interactive Web App to create custom posters with variable fonts."
                        cta={{
                            text: "View more",
                            url: "https://www.instagram.com/p/B1qtCCKpLtv/?utm_medium=copy_link",
                        }}
                    />
                    <Item.Other
                        name="Bastarda Type"
                        description="Front-end dev for Bastarda Type website redesign."
                        cta={{
                            text: "View website",
                            url: "https://www.bastardatype.com/",
                        }}
                    />
                </StyledProjectGrid>
            </div>
            {/* <div>
                <Heading.Section title="Around the web - Mentions" />
                <Item.Other
                    name="Awwwards Mobile Excellence"
                    description="Bastarda Type"
                />
                <Item.Other
                    name="Gold - Latin American Design Awards"
                    description="Bastarda Type"
                />
            </div> */}
        </StyledContainer>
    </Layout>
);

export const workListQuery = graphql`
    query WorkList {
        allMdx(
            filter: { frontmatter: { visible: { eq: true } } }
            sort: { fields: frontmatter___order, order: ASC }
        ) {
            edges {
                node {
                    id
                    slug
                    frontmatter {
                        title
                        tagline
                    }
                }
            }
        }
    }
`;

export default IndexPage;
