import * as React from "react";

type HeadingProps = {
    Section: React.FunctionComponent<SectionHeadingProps>;
};

type SectionHeadingProps = {
    title: string;
};

const Heading: HeadingProps = {
    Section: ({ title }) => {
        return <h3>{title}</h3>;
    },
};

export default Heading;
