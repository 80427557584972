import { Link } from "gatsby";
import * as React from "react";
import Text from "../Typography/Text";
import { IParallax, Parallax, ParallaxLayer } from "@react-spring/parallax";
import { useSpring, animated } from "react-spring";
//@ts-ignore
import person from "../../images/person.png";
import styled from "styled-components";
import { StyledMarquee } from "../Item/Item";

const StyledIntroTagline = styled.div`
    width: 100%;
    @media screen and (min-width: 600px) {
    }
    @media screen and (min-width: 1024px) {
        width: 70%;
    }
`;

const StyledAvatarImage = styled.img`
    width: 150px;
    height: auto;
    @media screen and (min-width: 600px) {
        width: 250px;
    }
    @media screen and (min-width: 1024px) {
        width: 350px;
    }
`;

const StyledParallax = styled(animated.div)`
    height: auto;
    position: absolute;
    right: 0;
    top: 460px;

    @media screen and (min-width: 600px) {
    }
    @media screen and (min-width: 1024px) {
        right: 35px;
        top: 320px;
    }
`;

export function debounce(func, wait = 5, immediate = true) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

const Hero: React.FunctionComponent = () => {
    const parallax = React.useRef<IParallax>(null);
    const [scrollY, setScrollY] = React.useState(0);
    React.useEffect(() => {
        const handleScroll = () => setScrollY(window.scrollY);
        window.addEventListener("scroll", debounce(handleScroll));
        return () =>
            window.removeEventListener("scroll", debounce(handleScroll));
    }, [debounce]);

    const [{ springscrollY }, springsetScrollY] = useSpring(() => ({
        springscrollY: 0,
    }));
    const parallaxLevel = -10;
    springsetScrollY({ springscrollY: scrollY });
    const interpHeader = springscrollY.to(
        (o) => `translateY(${o / parallaxLevel}px)`
    );
    return (
        <>
            <div
                style={{ display: "grid", rowGap: "2em", position: "relative" }}
            >
                <Text.Large>
                    Hello! I'm a Product Designer building things people love to
                    use and tools helping others create, with an unbearable love
                    for prototyping and code.
                </Text.Large>
                <StyledIntroTagline>
                    <Text.Medium>
                        Currently Staff Product Designer for Design Systems at Lulo Bank.
                    </Text.Medium>
                </StyledIntroTagline>
                <Link to="/about">
                    <StyledMarquee>
                        <Text.Body interactive>More about me</Text.Body> ⟶
                    </StyledMarquee>
                </Link>
                <StyledParallax style={{ transform: interpHeader }}>
                    <StyledAvatarImage src={person} />
                </StyledParallax>
            </div>
        </>
    );
};
export default Hero;
